<template>
  <div class="app-content content ">
    <div class="content-overlay" />
    <div class="header-navbar-shadow" />
    <div class="content-wrapper">
      <div class="content-header row">
        <div class="content-header-left col-md-9 col-12 mb-2">
          <div class="row breadcrumbs-top">
            <div class="col-12">
              <h2 class="content-header-title float-start mb-0">
                {{ item.id ? 'Edit' : 'New' }} memoir<br>
              </h2>
            </div>
          </div>
        </div>
        <div class="content-header-right text-md-end col-md-3 col-12 d-md-block d-none">
          <div class="mb-1 breadcrumb-right">
            <a
              class="btn btn-icon btn-secondary mx-50"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Back"
              @click="$router.push({name: 'admin.memoir.index'})"
            ><i data-feather="chevron-left" /></a>
            <!-- <a
              href="#"
              class="btn btn-primary"
              data-bs-toggle="tooltip"
              data-bs-placement="top"
              title="Generate pdf Memoir Profile Proof "
            ><i data-feather="file" /> Generate profile proof</a> -->
          </div>
        </div>
      </div>
      <div class="content-body content-body--senior-call">
        <div class="row">
          <div class="col-12">

            <div class="card">
              <div class="card-header">
                <h3 class="cart-title">
                  <a data-action="collapse">Content</a>
                </h3>
                <div class="heading-elements">
                  <ul class="list-inline mb-0">
                    <li>
                      <a data-action="collapse"><i data-feather="chevron-down" /></a>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="card-content collapse show">
                <div class="card-body">
                  <div class="row">
                    <div class="col-sm-8">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.title'] }}</label>
                        <input
                          v-model="item.title"
                          type="text"
                          class="form-control"
                        >
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.year'] }}</label>
                        <date-picker
                          v-model="item.year"
                          format="Y"
                          type="year"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-2">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.status'] }}</label>
                        <v-select
                          v-model="item.status"
                          label="status"
                          :options="statuses"
                          :get-option-key="option => option.id"
                        />
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="col">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.description'] }}</label>
                        <quill-editor v-model="item.description" />
                      </div>
                    </div>
                  </div>
                  <hr>

                  <div class="row">
                    <div class="col-sm-6">
                      <h5>Memoir file</h5>
                      <div class="row align-items-end">
                        <div class="col-sm-9">
                          <div class="mb-1">
                            <div
                              class="input-group custom-file-button"
                            >
                              <label
                                class="input-group-text"
                                for="formFile"
                              >Select file</label>
                              <input
                                id="formFile"
                                :ref="`file-memoir-file`"
                                class="form-control"
                                type="file"
                                @change="uploadFile('file-memoir-file')"
                              >
                            </div>
                          </div>
                        </div>
                        <div
                          v-if="file_url"
                          class="col-sm-3"
                        >
                          <div class="mb-1">
                            <a
                              :href="file_url"
                              class="btn bg-light-secondary w-100"
                              target="_blank"
                            ><i data-feather="external-link" /> Open file</a>
                          </div>
                        </div>
                      </div>
                      <small class="mt-1">
                        {{ labels['content.file_size'] }} <strong>30 MB</strong>.<br>
                        {{ labels['content.allowed_file_types'] }} <strong>pdf</strong>.
                      </small>
                      <p class="mt-1">
                        <a
                          class="btn bg-light-danger btn-sm"
                          @click="deleteFile('file')"
                        ><i data-feather="trash-2" /> Delete file</a>
                      </p>
                    </div>
                    <div class="col-sm-6">
                      <h5>Link memoir</h5>
                      <div class="row">
                        <div class="col">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['content.title_link_memoir'] }}</label>
                            <input
                              v-model="item.memoir_link_title"
                              type="text"
                              class="form-control"
                              maxlength="128"
                            >
                          </div>
                        </div>
                      </div>
                      <div class="row align-items-end">
                        <div class="col">
                          <div class="mb-1">
                            <label
                              for=""
                              class="form-label"
                            >{{ labels['content.url_link_memoir'] }}</label>
                            <div class="input-group form-password-toggle mb-1">
                              <input
                                id="urlmemoir"
                                v-model="item.url_memoir"
                                type="url"
                                class="form-control"
                                placeholder="https://academy.icrea.cat/knowledge-base/article/..."
                                aria-describedby="urlmemoir"
                              >
                              <a
                                v-if="!disabledLink"
                                :href="item.url_memoir"
                                target="_blank"
                                class="input-group-text cursor-pointer"
                                data-bs-toggle="tooltip"
                                data-bs-placement="top"
                                title="Go to URL memoir"
                              ><i data-feather="link" /></a>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h5>Memoir image</h5>
                  <div class="row">
                    <div class="col-sm-6">
                      <div class="row">
                        <div
                          v-if="blob_url || icon_path"
                          class="col-sm-4"
                        >
                          <img
                            v-if="blob_url"
                            height="100%"
                            width="100%"
                            :alt="item.alt_text"
                            :src="blob_url"
                          >
                          <img
                            v-if="icon_path"
                            height="100%"
                            width="100%"
                            :alt="item.alt_text"
                            :src="icon_path"
                          >
                        </div>
                        <div class="col-sm-8">
                          <div class="mb-1">
                            <div
                              class="input-group custom-file-button"
                            >
                              <label
                                class="input-group-text"
                                for="formFileImage"
                              >Select file</label>
                              <input
                                id="formFileImage"
                                :ref="`file-image`"
                                class="form-control"
                                type="file"
                                @change="uploadFile('file-image')"
                              >
                            </div>
                          </div>
                          <small class="mt-1">
                            {{ labels['content.file_size'] }} <strong>2 MB</strong>.<br>
                            {{ labels['content.allowed_file_types'] }} <strong>png, gif, webp or jpg</strong>.
                          </small>
                          <p class="mt-1">
                            <a
                              class="btn bg-light-danger btn-sm"
                              @click="deleteFile('image')"
                            ><i data-feather="trash-2" /> Delete image</a>
                          </p>
                        </div>
                      </div>
                    </div>
                    <div class="col-sm-6">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.memoir_image_alt'] }}</label>
                        <input
                          v-model="item.alt_text"
                          type="text"
                          class="form-control"
                        >
                      </div>
                      <small>{{ labels['content.memoir_image_alt_description'] }}</small>
                    </div>
                  </div>
                  <hr>
                  <h5>Memoir Dates</h5>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.begin_date_promotion'] }}</label>
                        <date-picker
                          v-model="item.promotion_begin_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.end_date_promotion'] }}</label>
                        <date-picker
                          v-model="item.promotion_end_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.begin_date'] }}</label>
                        <date-picker
                          v-model="item.begin_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.end_date'] }}</label>
                        <date-picker
                          v-model="item.end_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h5>Notification dates</h5>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.notification_date'] }}</label>
                        <date-picker
                          v-model="item.notification_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                      <small>{{ labels['content.notification_date_description'] }}</small>
                    </div>
                    <div class="col-sm-4">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.open_memoir_date'] }}</label>
                        <date-picker
                          v-model="item.open_memoir_date"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                      <small>{{ labels['content.open_memoir_date_description'] }}</small>
                    </div>
                  </div>
                  <hr>
                  <h5>Highlights dates</h5>
                  <div class="row">
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.begin_date_promotion_highlights'] }}</label>
                        <date-picker
                          v-model="item.promotion_begin_date_high"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.begin_date_highlights'] }}</label>
                        <date-picker
                          v-model="item.begin_date_icrea_high"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                    <div class="col-sm-3">
                      <div class="mb-1">
                        <label
                          for=""
                          class="form-label"
                        >{{ labels['content.end_date_highlights'] }}</label>
                        <date-picker
                          v-model="item.end_date_high"
                          format="D MMM Y"
                          value-type="format"
                          class="w-100"
                        />
                      </div>
                    </div>
                  </div>
                  <hr>
                  <h5>Instructions links</h5>
                  <p>{{ labels['content.instructions_description'] }}</p>
                  <div class="row">
                    <div class="col-sm-4">
                      <div class="input-group form-password-toggle mb-1">
                        <input
                          id="url1"
                          v-model="item.knowledge_base_link_1"
                          type="url"
                          class="form-control"
                          placeholder="https://academy.icrea.cat/knowledge-base/article/..."
                          aria-describedby="url1"
                          @change="validateUrl($event.target.value, 'k1')"
                        >
                        <a
                          v-if="!disabledLinkK1"
                          :href="item.knowledge_base_link_1"
                          target="_blank"
                          class="input-group-text cursor-pointer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Go to link"
                        ><i data-feather="link" /></a>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="input-group form-password-toggle mb-1">
                        <input
                          id="url2"
                          v-model="item.knowledge_base_link_2"
                          type="url"
                          class="form-control"
                          placeholder="https://academy.icrea.cat/knowledge-base/article/..."
                          aria-describedby="url2"
                          @change="validateUrl($event.target.value, 'k2')"
                        >
                        <a
                          v-if="!disabledLinkK2"
                          :href="item.knowledge_base_link_2"
                          target="_blank"
                          class="input-group-text cursor-pointer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Go to link"
                        ><i data-feather="link" /></a>
                      </div>
                    </div>
                    <div class="col-sm-4">
                      <div class="input-group form-password-toggle mb-1">
                        <input
                          id="url3"
                          v-model="item.knowledge_base_link_3"
                          type="url"
                          class="form-control"
                          placeholder="https://academy.icrea.cat/knowledge-base/article/..."
                          aria-describedby="url3"
                          @change="validateUrl($event.target.value, 'k3')"
                        >
                        <a
                          v-if="!disabledLinkK3"
                          :href="item.knowledge_base_link_3"
                          target="_blank"
                          class="input-group-text cursor-pointer"
                          data-bs-toggle="tooltip"
                          data-bs-placement="top"
                          title="Go to link"
                        ><i data-feather="link" /></a>
                      </div>
                    </div>
                  </div>

                </div>
              </div>
            </div>

          </div>
        </div>

        <div class="page-actions">
          <button
            v-if="!sending"
            id="type-success"
            class="btn btn-success"
            @click="save"
          >
            <i data-feather="save" /> Save and Exit
          </button>
          <button
            v-else
            class="btn btn-outline-success"
            type="button"
            disabled=""
          >
            <span
              class="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
            <span class="ms-25 align-middle">Saving...</span>
          </button>
        </div>

      </div>
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import { mapGetters } from 'vuex'
import DatePicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import vSelect from 'vue-select'

export default {
  components: {
    DatePicker,
    vSelect,
  },
  data() {
    return {
      memoirId: this.$route.params.id,
      dateAlert: 0,
      sending: false,
      errors: '',
      blob_url: '',
      icon_path: '',
      file_url: '',
      aux_memoir_link_title: '',
      disabledLink: true,
      disabledLinkK1: true,
      disabledLinkK2: true,
      disabledLinkK3: true,
    }
  },
  computed: {
    ...mapGetters({
      item: 'memoirs/item',
      statuses: 'status/statuses',
      labels: 'sectionLabels/itemLabels',
    }),
    urlMemoir() {
      return this.item.url_memoir
    },
  },
  watch: {
    urlMemoir() {
      if (this.item && this.item.url_memoir && this.item.url_memoir.length > 0) {
        this.disabledLink = false
      } else {
        this.disabledLink = true
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
  async mounted() {
    this.$store.dispatch('sectionLabels/fetchLabels', 'memoirs.memoir_list')
    if (this.memoirId) {
      await this.$store.dispatch('memoirs/fetchId', this.memoirId)
      if (this.item) {
        this.file_url = this.item.file ? this.item.file.url : null
        this.icon_path = this.item.image ? this.item.image.url : null
        this.disabledLink = !this.item.url_memoir
        this.disabledLinkK1 = !this.item.knowledge_base_link_1
        this.disabledLinkK2 = !this.item.knowledge_base_link_2
        this.disabledLinkK3 = !this.item.knowledge_base_link_3
      }
    } else {
      await this.$store.dispatch('memoirs/cleanType')
    }
    this.$store.dispatch('status/filterStatus', 'Memoirs')
    setTimeout(() => {
      feather.replace({
        width: 14,
        height: 14,
      })
    }, 500)
  },
  methods: {
    async save() {
      this.sending = true
      try {
        if (this.memoirId) {
          await this.$store.dispatch('memoirs/update', { id: this.memoirId, data: this.item })
        } else {
          await this.$store.dispatch('memoirs/create', this.item)
        }
        this.errors = ''
      } catch (e) {
        this.errors = e.response.data.message
      }

      if (this.errors === '' && this.item.id) {
        Vue.swal('The memoir has been saved correctly!', '', 'success').then(() => {
          this.$router.push({ name: 'admin.memoir.index' })
        })
      } else {
        // Vue.swal
      }

      this.sending = false
    },
    async onSearch(search, store) {
      search.active = true
      if (search.name !== '') {
        await this.$store.dispatch(store, search)
      }
    },
    async uploadFile(name) {
      const file = this.$refs[name].files[0]

      if (name === 'file-memoir-file') {
        const fileData = {
          name: file.name,
          original_name: file.name,
          description: '',
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          to: '',
          file,
          url: URL.createObjectURL(file),
          aux: name,
        }

        this.item.file = fileData
        this.file_url = URL.createObjectURL(file)
        setTimeout(() => {
          feather.replace({
            width: 14,
            height: 14,
          })
        }, 500)
      } else if (name === 'file-image') {
        const fileData = {
          name: file.name,
          original_name: file.name,
          description: '',
          weight: (file.size / 1024 / 1024).toFixed(2),
          extension: file.type,
          created_at: 'Pending to save...',
          to: '',
          file,
          url: URL.createObjectURL(file),
          aux: name,
        }

        this.item.image = fileData
        this.icon_path = ''
        this.blob_url = URL.createObjectURL(file)
      }
      // await Vue.prototype.$http.post(`${Vue.prototype.$groupUrl}/convo/${this.convo.id}/save-files`, fileData)
      // await Vue.prototype.$saveFiles([fileData])
      // this.$toastr.success('', 'Document uploaded successfully!')
      // this.getFiles()
    },
    deleteFile(name) {
      switch (name) {
        case 'image':
          // DELETE IMAGE
          this.item.image = null
          this.icon_path = ''
          break
        case 'file':
          // DELETE FILE
          this.item.file = null
          this.file_url = null
          break
        default:
          break
      }
    },
    validateUrl(value, name) {
      switch (name) {
        case 'k1':
          this.disabledLinkK1 = !(value.length > 0)
          break
        case 'k2':
          this.disabledLinkK2 = !(value.length > 0)
          break
        case 'k3':
          this.disabledLinkK3 = !(value.length > 0)
          break
        default:
          break
      }
      setTimeout(() => {
        feather.replace({
          width: 14,
          height: 14,
        })
      }, 500)
    },
  },
}
</script>
